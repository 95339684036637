<template>
  <header :class="['header', { home: $route.path !== '/' }]">
    <div class="container">
      <div class="header__sort" v-if="$route.path == '/'">
        <div :class="['header__sort-chip', 'all', {checked: getMapSortBy?.id === all.id}]" @click="setMapSort(all)">
          <p class="header__sort-chip-text">
            {{ all.text }}
          </p>
        </div>
        <div class="header__sort-slider slider">
          <img @click="prev" class="slider__prev" src="@/assets/svg/arrows/up-black.svg" alt="" v-if="sliderTranslate !== 0">
          <div class="slider__wrapper">
            <div :style="translate" class="slider__wrapper-items">
              <div
                  :class="['header__sort-chip', {checked: getMapSortBy?.id === item.id}]"
                  v-for="(item, key) in getDamageRanges"
                  :key="key"
                  @click="setMapSort(item)"
                  v-show="item.status !== 'Moderation' || getUser?.role === 'Admin' || getUser?.role === 'Moderator'"
              >
                <div class="header__sort-chip-circle" :style="{backgroundColor: item.color}" v-if="item.color"></div>
                <div class="header__sort-chip-text">
                  <p v-html="item.text"></p>
                  <span v-if="item.minValue === 1000001">{{ $t('filter-subtitle-1') }}</span>
                  <span v-if="item.minValue === 500001">{{ $t('filter-subtitle-2', {from: '500 001', to: '1 000 000'}) }}</span>
                  <span v-if="item.minValue === 100001">{{ $t('filter-subtitle-2', {from: '100 001', to: '500 000'}) }}</span>
                  <span v-if="item.minValue === 0">{{ $t('filter-subtitle-3', {to: '100 000'}) }}</span>
                </div>
              </div>
            </div>
          </div>
          <img @click="next" class="slider__next" src="@/assets/svg/arrows/up-black.svg" alt="" v-show="sliderTranslate === 0">
        </div>
      </div>
      <div class="header__content">
        <div class="header__content-wrap">
          <side-bar-auth />
          <!--      <router-link to="/panel">-->
          <!--        <img class="header__wrap-logo" src="@/assets/svg/logo/with-text.svg" alt="">-->
          <!--      </router-link>-->
        </div>
        <router-link to="/profile">
          <div ref="user" class="header__content-user user">
            <div
                v-if="getUser?.role !== 'Government' && getUser?.role !== 'Company'"
                :class="['user__name', {'user__name-sort': $route.name === 'Home'}]"
            >{{ getUserName }}</div>
            <div v-else :class="['user__name', {'user__name-sort': $route.name === 'Home'}]">{{ getUser?.companyName }}</div>
            <img :src="getUserAvatar(getUser?.avatarSrc?.fileSmallSrc)" class="user__avatar" alt="" />
          </div>
        </router-link>

        <ui-loader-big v-model="visibleBigLoader" />
      </div>
      </div>
  </header>
</template>


<script>
import SideBarAuth from "@/components/common/SideBarAuth";
import {mapActions, mapGetters, mapMutations} from "vuex";
import {getUserAvatar, getUserRole} from "@/utils/user";
import UiLoaderBig from "@/components/ui/UiLoaderBig";
import {getCookie, setCookie} from "../utils/cookie";
export default {
  name: "HeaderAuth",
  components: {
    UiLoaderBig,
    SideBarAuth
  },
  data() {
    return {
      getUserAvatar,
      visibleBigLoader: true,
      all: {id: 0, text: this.$t('all'), status: 'All'},
      sortBy: null,
      userBoxWidth: '',
      index: 0,
      sliderTranslate: 0
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getMapSortBy',
      'getDamageRanges',
      'getLanguages'
    ]),

    getUserName () {
      return this.getUser?.role === 'Admin' ? this.getUser?.login : this.getUser?.firstName && this.getUser?.lastName ? this.getUser?.firstName + ' ' + this.getUser?.lastName : ''
    },

    translate() {
      return {transform: `translateX(-${this.sliderTranslate}px)`}
    },
    // home () {
    //   return this.$route.path === '/'
    // }
  },
  methods: {
    getUserRole,
    ...mapActions([
        'user',
        'reqRegions',
        'reqOrderTypes',
        'orderDamageRanges',
        'languages'
    ]),
    ...mapMutations([
        'setMapSort'
    ]),

    prev() {
      this.sliderTranslate = 0
    },
    next() {
      if (this.getUser?.role === 'Admin' || this.getUser?.role === 'Moderator') {
        if(window.innerWidth > 1400) {
          this.sliderTranslate = 310
        } else if(window.innerWidth < 1200) {
          this.sliderTranslate = 610
        } else if(window.innerWidth < 1300) {
          this.sliderTranslate = 510
        } else if(window.innerWidth < 1400) {
          this.sliderTranslate = 410
        }
      } else {
        if(window.innerWidth > 1400) {
          this.sliderTranslate = 250
        } else if(window.innerWidth < 1200) {
          this.sliderTranslate = 550
        } else if(window.innerWidth < 1300) {
          this.sliderTranslate = 450
        } else if(window.innerWidth < 1400) {
          this.sliderTranslate = 350
        }
      }
    }
  },

  created() {
    Promise.all([
      this.user(),
    ])
  },

  mounted () {
    setTimeout(() => {
      this.userBoxWidth = this.$refs.user.clientWidth + 55 + 'px'
    }, 1000)

    setTimeout(() => {
      this.languages().then(() => {
        this.getLanguages.forEach(el => {
          if(el.code === this.getUser?.locale) {
            if(this.getUser?.locale === getCookie('locale')) {
              setTimeout(() => {
                Promise.all([
                  this.reqRegions(),
                  this.reqOrderTypes(),
                ]).then(() => {
                  this.visibleBigLoader = false
                })
                if (this.getDamageRanges.length === 3) {
                  this.orderDamageRanges()
                }
              }, 1000)
            } else {
              setTimeout(() => {
                this.$i18n.locale = el.code
                setCookie('locale', el.code)
                location.reload()
              }, 500)
            }
          }
        })
      })
    }, 500)
  },

  destroyed() {

  }
}
</script>

<style lang="scss" scoped>
.header {
  position: fixed;
  left: 0;
  right: 0;
  z-index: 50;
  background: transparent;

  &__sort {
    position: absolute;
    top: 23px;
    left: 104px;
    right: v-bind(userBoxWidth);
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 50;
    max-width: 1200px;
    overflow: hidden;
    padding-bottom: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 1200px) {
      top: 27px;
    }

    @media (max-width: 768px) {
      right: 30px;
      left: 80px;
    }

    &-chip {
      display: flex;
      align-items: flex-start;
      gap: 8px;
      padding: 10px 16px;
      border-radius: 100px;
      background: #FFFFFF;
      box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
      cursor: pointer;
      height: 48px;
      z-index: 1;

      &.all {
        align-items: center;
      }

      &.checked {
        background: #E21F1F;

        .header__sort-chip-text {
          color: #FFFFFF;

          span {
            color: #FFFFFF;
          }
        }
      }

      &-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-top: 3px;
      }

      &-text {
        color: #1B1A1F;
        font-size: 12px;
        font-weight: 400;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        gap: 3px;

        span {
          color: #1B1A1F;
          font-family: "Gotham Pro Regular";
          font-size: 12px;
          font-weight: 400;
        }
      }
    }

    .slider {
      width: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      &__prev {
        transform: rotate(-90deg);
        padding: 10px 10px;
        border-radius: 100px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
        z-index: 1;
        cursor: pointer;

        @media (max-width: 1100px) {
          display: none;
        }
      }

      &__next {
        transform: rotate(90deg);
        padding: 10px 10px;
        border-radius: 100px;
        background: #FFFFFF;
        box-shadow: 2px 2px 10px 0 rgba(153, 153, 153, 0.20);
        z-index: 1;
        cursor: pointer;

        @media (max-width: 1100px) {
          display: none;
        }
      }

      &__wrapper {
        position: relative;
        width: 100%;
        overflow: hidden;

        &-items {
          position: absolute;
          margin-right: v-bind(userBoxWidth);
          display: flex;
          gap: 10px;
          transition: .3s;
          padding-left: 10px;
          width: 100%;

          @media (max-width: 1100px) {
            position: static;
            overflow-y: scroll;
            padding-right: 70px;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 50px;

    @media (max-width: 1200px) {
      padding: 30px 20px;
    }

    &-wrap {
      display: flex;
      gap: 8px;

      &-logo {
        display: none;
        width: 119px;
        height: 36px;
        margin-left: 8px;

        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }

  &.home {
    background: #F5F5F5 !important;
  }
}

.user {
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    display: none;
  }

  &__avatar {
    width: 44px;
    height: 44px;
    border: 1px solid #E21F1F;
    filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.2));
    box-sizing: border-box;
    border-radius: 50%;
    object-fit: cover;
  }

  &__name-sort {
    max-width: 110px;
  }

  &__name {
    max-width: 500px;
    text-align: right;
  }
}
</style>
